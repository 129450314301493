import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { mobilePromotion, promotions, mobilePromoA, mobilePromoB } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          {/* <CFImage src={mobilePromotion} w="95%" alt="Promotions" /> */}
          <CFLink target="_blank" href="">
            <CFImage src={mobilePromoA} w="95%" alt="Promotions" />
          </CFLink>
          <CFImage src={mobilePromoB} w="95%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1100px" pv="30px">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
