import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  mobileOrderButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) left / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
        >
          <Header />

          <CFView
            textCenter
            column
            center
            ph="5%"
            pt="5px"
            w="100%"
            maxWidth="1200px"
            pt="10px"
            bg="rgba(0,0,0,.7)"
            bc="#B88F60"
            bw="1.5px"
          >
            <CFView column center>
              <CFText color="#B88F60" style={{ fontSize: 36 }} raleway xBold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView column center>
              <CFView pt="10px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView mt="-5px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed', backgroundSize: '1400px' }}
          zIndex={90}
          column
          center
          justifyBetween
        >
          <Header />
          <CFView
            row
            center
            w="100%"
            maxWidth="1200px"
            pv="5px"
            bg="rgba(0,0,0,.7)"
            bc="#B88F60"
            bw="1.5px"
          >
            <CFView row justifyStart mr="20px">
              <CFText color="#B88F60" style={{ fontSize: 36 }} raleway xBold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center>
              <CFView pt="10px" mr="10px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView pt="10px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
