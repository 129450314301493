import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { appStore, googlePlay } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mb="13px" mr="25px" column center>
          <CFView h3 futura bold color="#B88F60" mb="20px">
            DOWNLOAD APP
          </CFView>
          <CFView column center white>
            <CFView h4 mb="15px" textCenter maxWidth="75vw">
              Applause Sushi is now taking online orders. Order through our
              website or download our app in your app store! Collect points and
              redeem fantastic rewards!
            </CFView>
            <CFView row center>
              <CFView mr="10px">
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.applause">
                  <CFImage
                    h="40px"
                    src={appStore}
                    alt="Applause Sushi App Store"
                  />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.applause">
                <CFImage
                  h="40px"
                  src={googlePlay}
                  alt="Applause Sushi Google Play"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mr="25px" column justifyStart>
          <CFView h3 futura bold color="#B88F60" mb="20px" minWidth="250px">
            DOWNLOAD APP
          </CFView>
          <CFView column justifyStart white>
            <CFView maxWidth="260px" fontSize="16px" mb="20px">
              Applause Sushi is now taking online orders. Order through our
              website or download our app in your app store! Collect points and
              redeem fantastic rewards!
            </CFView>
            <CFView row justifyStart alignCenter>
              <CFView mr="10px">
                <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.applause">
                  <CFImage
                    h="37px"
                    mt="5px"
                    src={appStore}
                    alt="Applause Sushi App Store"
                    hover
                  />
                </CFLink>
              </CFView>
              <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.applause">
                <CFImage
                  h="37px"
                  mt="5px"
                  src={googlePlay}
                  alt="Applause Sushi Google Play"
                  hover
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
